import React, { useState, useEffect } from "react";
import Image from 'next/image';
import Head from "next/head";
import bgGreenCircles from '@skip-scanner/ui/img/wallpapers/Circles.webp'
import { BreakpointOverlayProvider } from 'lib/providers'
import { useIsClient } from 'usehooks-ts'

/**
 * The props that need to be passed as a type to `AuthLayout`.
 * @property {React.ReactNode} [children] - React nodes (elements/components) to be rendered as the main content inside the layout.
 * @property {string} title - The title for the layout or page.
 */
type AuthLayoutProps = {
  children?: React.ReactNode,
  title: string
}

/**
 * Represents the authentication layout of the application. The layout consists of a main content 
 * area where authentication forms (like login or register) can be rendered and a decorative section 
 * with an image on the side.
 * 
 * The layout ensures that the UI components are rendered only on the client side.
 * 
 * @component
 * @param {Object} props - Properties passed down to the AuthLayout component.
 * @returns {ReactElement} Returns the authentication layout of the application.
 */
export const AuthLayout:React.FC<AuthLayoutProps> = ({ children, title }) => {

  const isClient = useIsClient()

  if(!isClient) return null;

  return (
    <>
      <Head>
        <title>{title} | Skip Scanner</title>
        <link rel='icon' href='/favicon.ico' />
      </Head>

      <BreakpointOverlayProvider>

        <main className="grid grid-rows-1 grid-cols-10 lg:grid-cols-12 h-screen overflow-hidden max-md:hidden">

          <div id="leftPane" className="md:col-span-7 lg:col-span-7 overflow-y-auto">
            <div className="px-12 lg:px-16 xl:px-24 h-full flex flex-col">

              {children}

            </div>
          </div>

          <div id="rightPane" className="md:col-span-3 lg:col-span-5 relative">
            <Image 
              className='object-cover inset-0 absolute w-full h-full'
              src={bgGreenCircles}
              fetchPriority="high"
              placeholder="blur"
              alt='Green wallpaper'
              width={5670}
              height={5670}
            />
          </div>

        </main>

      </BreakpointOverlayProvider>
    </>
  )
}