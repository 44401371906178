import React, { useState, useEffect } from 'react'
import moment, { weekdays } from 'moment'

/**
 * `useWeekDates` is a hook that calculates the dates and time slots within a specified week.
 * This function is used to generate an array of date and time objects based on the given week number,
 * time range, and an option to include weekends.
 *
 * @function
 * @param {Object} options - The configuration options for generating week dates and time slots.
 * @param {number} options.weekNumber - The number of the week for which dates and time slots are to be generated.
 * @param {boolean} [options.includeWeekends=false] - Indicates whether to include weekends in the date and time calculations.
 * @param {string} options.startTime - The start time of the time range in HH:mm format.
 * @param {string} options.endTime - The end time of the time range in HH:mm format.
 *
 * @returns {Object} Returns an object containing two properties: weekDates and timeDates.
 * @returns {Date[]} weekDates - An array of Date objects representing the dates within the specified week.
 * @returns {string[]} timeDates - An array of string time slots within the specified time range for each day.
 */
export const useWeekDates = (args: {weekNumber: number, includeWeekends: boolean, startTime: string, endTime: string }) => {

  const [weekDates, setWeekDates] = useState<{ date: Date; isToday: boolean }[]>([]);
  const [timeDates, setTimeDates] = useState<moment.Moment[]>([]);

  useEffect(() => {
    const weekStart = moment().week(args.weekNumber).startOf('isoWeek').toDate();
    const weekEnd = args.includeWeekends ? moment().week(args.weekNumber).endOf('isoWeek').toDate() : moment().week(args.weekNumber).isoWeekday(5).toDate();

    const weekDates: { date: Date; isToday: boolean }[] = [];1  
    let currentDate = weekStart;
    while (currentDate <= weekEnd) {
      weekDates.push({
        date: new Date(currentDate),
        isToday: moment(currentDate).isSame(new Date(), 'day'),
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setWeekDates(weekDates);

    const startMoment = moment(args.startTime, 'HH:mm');
    const endMoment = moment(args.endTime, 'HH:mm');

    const hours: moment.Moment[] = [];
    let currentHour = startMoment;
    while (currentHour.isBefore(endMoment)) {
      hours.push(moment(currentHour));
      currentHour.add(1, 'hour');
    }

    setTimeDates(hours)

  }, [args.weekNumber, args.includeWeekends, args.startTime, args.endTime]);

  return { 
    weekDates: weekDates,
    timeDates: timeDates
  };
}