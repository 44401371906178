import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { useEventCallback, useEventListener } from 'usehooks-ts';

declare global {
  interface WindowEventMap {
    'local-storage': CustomEvent;
  }
}

type SetValue<T> = Dispatch<SetStateAction<T>>;

/**
 * @typedef {object} Options - The options object for useLocalStorage.
 * @property {function} [serializer] - Custom function to serialize data.
 * @property {function} [deserializer] - Custom function to deserialize data.
 */
type Options<T> = {
  serializer?: (value: T) => string;
  deserializer?: (value: string) => T;
  timeout?: number
};

/**
 * A React hook to synchronize a value with local storage. 
 * The value in the local storage is JSON serialized and optionally given a timeout after which it's considered expired.
 *
 * @template T - The type of the value to be stored.
 *
 * @param {string} key - The key under which the value is stored in local storage.
 * @param {T} initialValue - The initial value to be used if there is no value in local storage or if the value in local storage has expired.
 * @param {Options<T>} [options] - Optional parameter where custom serializer, deserializer, and timeout can be defined.
 * 
 * @return {[T, SetValue<T>]} - A tuple where the first item is the stored value (or the initial value) and the second item is a function to set the new value. The set function accepts both the value itself or a function that takes the previous value and returns the new value (similar to the setState function from React).
 *
 * @example
 * 
 * const [value, setValue] = useLocalStorage('myKey', 'myInitialValue', {
 *   timeout: 5000, // The value will expire in 5000 milliseconds (5 seconds)
 *   serializer: (value) => customSerializer(value),
 *   deserializer: (value) => customDeserializer(value),
 * });
 */
export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  options?: Options<T>,
): [T, SetValue<T>] {

  const serializer = options?.serializer || JSON.stringify;
  const deserializer = options?.deserializer || JSON.parse;

  const readValue = useCallback((): T => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);

      if (item) {
        const { value, timestamp } = JSON.parse(item);
        
        if (options?.timeout && Date.now() - timestamp >= options?.timeout) {
          window.localStorage.removeItem(key); // Remove expired data
          return initialValue;
        }
        return deserializer(value); // Adjusted to work with the nested structure
      } 
    } 
    catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      window.localStorage.removeItem(key); // Consider removing corrupted data
    }

    return initialValue;
  }, [initialValue, key, deserializer, options?.timeout]);

  const [storedValue, setStoredValue] = useState<T>(readValue);

  const setValue: SetValue<T> = useEventCallback((value) => {
    try {
      const newValue = value instanceof Function ? value(storedValue) : value;
  
      // Get existing data to retain the timestamp if it exists
      const existingItem = window.localStorage.getItem(key);
      let timestamp = Date.now();
  
      if (existingItem) {
        const existingData = JSON.parse(existingItem);
        timestamp = existingData.timestamp; // Retain the original timestamp
      }
  
      // Store the new value with the original timestamp
      window.localStorage.setItem(
        key,
        JSON.stringify({ value: serializer(newValue), timestamp })
      );
      setStoredValue(newValue);
      window.dispatchEvent(new Event('local-storage'));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  });

  useEffect(() => {
    setStoredValue(readValue());
  }, []);

  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent) => {
      if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
        return;
      }
      setStoredValue(readValue());
    },
    [key, readValue]
  );

  useEventListener('storage', handleStorageChange);
  useEventListener('local-storage', handleStorageChange);

  return [storedValue, setValue];
}