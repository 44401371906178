/**
 * This file defines Jotai atoms that are used in the app.
 * For now, these can be saved under 1 file, but if the app grows, it might be
 * better to split these atoms into separate files under the `atoms` directory.
 */

import { atom } from 'jotai'

/**
 * All of these atoms map to a specific Overlay component found in
 * [`components/overlays`](../components/overlays/index.ts). The advantage 
 * of using atoms for these overlays is that the state of the overlay can 
 * easily be applied and changed across multiple components. 
 * 
 * This makes it easier to split the logic of the overlay into multiple 
 * components and keep the app dynamic.
 */
export const overlayAtoms = {

  /**
   * ## Appointment Info Overlay Atom
   * ---
   * **Used by:** the schedule page, the calendar, and the event overlay.
   * 
   * This atom is created to dynamically manage / show the appointment info overlay.
   * This overlay is only shown when the appointment is not scannable. The value
   * of this atom is the ID of the appointment that is currently active, or `null`
   * if no appointment is active. This atom is used in the following components:
   * 
   * - `components/overlays/AppointmentInfo.tsx` - To read the current appointment info.
   * - `components/lesrooster/Calendar.tsx` - To set the active appointment.
   * - `pages/lesrooster.tsx` - To determine if the overlay should be shown.
   */
  appointmentInfo: atom<string | null>(null),

  /**
   * ## Invite Code Overlay Atom
   * ---
   * **Used by:** the schedule page, the invite code overlay and possibly the scan page.
   * 
   * This atom is created to dynamically show the invite code overlay. The value of
   * the atom is a boolean that determines if the overlay should be shown or not. This
   * atom is used in the following components:
   * 
   * - `components/overlays/InviteCode.tsx` - To show the overlay.
   * - `pages/lesrooster.tsx` - To determine if the overlay should be shown.
   */
  inviteCode: atom<boolean>(false),

  /**
   * ## Active Appointments Overlay Atom
   * ---
   * **Used by:** the scan page and the active appointments overlay.
   * 
   * This atom is created to dynamically show the active appointments overlay. The value
   * of the atom is a boolean that determines if the overlay should be shown or not. This
   * atom is used in the following components:
   * 
   * - `components/overlays/ActiveAppointments.tsx` - To show the overlay.
   * - `pages/scannen.tsx` - To determine if the overlay should be shown.
   */
  activeAppointments: atom<boolean>(false),

  /**
   * ## Hardware Devices Overlay Atom
   * ---
   * **Used by:** the scan page and the hardware devices overlay.
   * 
   * This atom is created to dynamically show the hardware devices overlay. The value
   * of the atom is a boolean that determines if the overlay should be shown or not. This
   * atom is used in the following components:
   * 
   * - `components/overlays/HardwareDevices.tsx` - To show the overlay.
   * - `pages/scannen.tsx` - To determine if the overlay should be shown.
   */
  hardwareDevices: atom<boolean>(false)
}