import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router';
import type { StepComponentProps } from 'lib/hooks/registration';
import { omit, isEmpty } from 'lodash';
import { Text, Button, MaskedInput, PasswordInput } from '@skip-scanner/ui'
import { REGEXP_AUTH_CODE_8_DIGITS } from '@skip-scanner/toolkit/lib/validators';
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from "react-hook-form";
import { motion } from 'framer-motion';
import { trpx } from 'lib/trpc';
import { z } from "zod";
import { TRPCError } from '@trpc/server';
import { 
  UndrawSoftwareEngineer, 
  UndrawPairProgramming, 
  UnDrawEducator,
  UndrawControlPanel
} from '@skip-scanner/ui/graphics/undraw'

type AddRoleProps = React.ForwardRefExoticComponent<React.HTMLAttributes<HTMLDivElement>> & {
  Form: React.FC<StepComponentProps>,
  Output: React.FC<StepComponentProps>
}

/**
 * The `AddTeacherRole` object has a form to collect data about the teacher (Zermelo koppelcode) and
 * an output layout which can represent the collected data, 
 * which is the employeeCode + some more relevant data that gets saved and displayed.
 */
export const AddTeacherRole = {
  Form: ({ increment, decrement, updateStep, data, ...props }) => {

    const [ buttonsEnabled, setButtonsEnabled ] = useState<boolean>(true)

    type AddTeacherRoleFormValues = {
      connectCode: string
    }
  
    const AddTeacherRoleFormSchema = z.object({
      connectCode: z.string()
        .min(12, 'Uw koppelcode moet bestaan uit 12 cijfers.').max(15, 'Uw koppelcode moet bestaan uit 12 cijfers.')
        .regex(
          new RegExp(`(\\d{12})|(\\d{3}-\\d{3}-\\d{3}-\\d{3})`, 'g'), // Also checks on dash in middle
          'Uw koppelcode moet bestaan uit 12 cijfers.'
        )
    })
  
    const {
      register,
      handleSubmit,
      getValues,
      setError,
      formState: { errors: formErrors },
    } = useForm<AddTeacherRoleFormValues>({ 
      resolver: zodResolver(AddTeacherRoleFormSchema), 
      defaultValues: {}
    })

    /**
     * Gets activated when all form values are checked and validated.
     * Calls to the server to authenticate are made here.
     */
    const handleNext = async () => {

      setButtonsEnabled(false)
      const connectCode = getValues().connectCode.replaceAll('-', '')

      try {
        const codeValue = await trpx.auth.validateConnectCode.query({ connectCode })

        updateStep('AddTeacherRoleForm', { data: codeValue })
        increment()
      }
      catch (err) {
        const error = err as TRPCError
        setError(error.code == 'UNAUTHORIZED' ? 'connectCode' : 'root', {
          message: error.message
        })
        setButtonsEnabled(true)
      }
    }

    return (
      <>
        {/* Form */}
        <motion.div className="flex flex-col flex-1 gap-y-8 py-[8vh]" {...omit(props, 'className')}>

          <MaskedInput
            {...register('connectCode')}
            label='Zermelo koppelcode'
            tooltipText={<>Deze vindt u in uw Zermelo Portal, onder <i>Portal &gt; Koppel App</i>.</>}
            mask='999-999-999-999'
            className='w-72 max-w-[45%] 2xl:max-w-[35%]'
            placeholder='Uw koppelcode'
          />

          {/* Errors */}
          { (!isEmpty(formErrors)) &&
            <div className="space-y-2 w-4/5">
              <Text.Label textScale={'label'} className="text-red-600">
                Er ging iets fout:
              </Text.Label>

              <Text.Paragraph className="text-paragraph-sm text-neutral-600">

                {formErrors?.connectCode && 
                  <>
                    <span className="font-medium">Zermelo koppelcode: {' '}</span>  
                    {formErrors.connectCode.message} <br/>
                  </>
                }

                {formErrors?.root && 
                  <>
                    <span className="font-medium">Algemeen: {' '}</span>  
                    {formErrors.root.message} <br/>
                  </>
                }

              </Text.Paragraph>
            </div>
          }

        </motion.div>

        {/* Bottom buttons */}
        <motion.div className="flex gap-x-6 items-center justify-between" {...omit(props, 'className')}>
          <Button 
            btnStyle={'primary'} 
            onClick={decrement}
            btnState={buttonsEnabled ? 'default' : 'disabled'}
          >
            Ga terug
          </Button>
          <Button 
            btnStyle={'primary-filled'} 
            onClick={handleSubmit(handleNext)}
            btnState={buttonsEnabled ? 'default' : 'disabled'}
          >
            Ga verder
          </Button>
        </motion.div>
      
      </>
    )
  },
  Output: ({ increment, decrement, updateStep, data, ...props }) => {

    return (
      <>
        {/* Form */}
        <motion.div className="flex flex-col flex-1 gap-y-8 py-[8vh]" {...omit(props, 'className')}>

          <UnDrawEducator 
            className='w-3/5'
          />

        </motion.div>

        {/* Bottom buttons */}
        <motion.div className="flex gap-x-6 items-center justify-between" {...omit(props, 'className')}>
          <Button btnStyle={'primary'} onClick={decrement}>Ga terug</Button>
          <Button btnStyle={'primary-filled'} onClick={increment}>Ga verder</Button>
        </motion.div>
      
      </>
    )
  }
} as AddRoleProps

/**
 * The `AddAdminRole` object has a form to collect the adminCode for the user and an output layout
 * which represents the success of the authorization.
 */
export const AddAdminRole = {
  Form: ({ increment, decrement, updateStep, data, ...props }) => {

    const [ buttonsEnabled, setButtonsEnabled ] = useState<boolean>(true)

    type AddAdminRoleFormValues = {
      adminCode: string
    }

    const AddAdminRoleFormSchema = z.object({
      adminCode: z.string()
        .min(8, 'Uw code moet bestaan uit 8 cijfers.').max(9, 'Uw code moet bestaan uit 8 cijfers.')
        .regex(
          new RegExp(`${REGEXP_AUTH_CODE_8_DIGITS}|(\\d{4}-\\d{4})`, 'g'), // Also checks on dash in middle
          'Uw code moet bestaan uit 8 cijfers.'
        )
    })

    const {
      register,
      handleSubmit,
      setValue,
      getValues,
      formState: { errors: formErrors },
      setError
    } = useForm<AddAdminRoleFormValues>({ 
      resolver: zodResolver(AddAdminRoleFormSchema), 
      defaultValues: {
        adminCode: data.AddAdminRoleForm?.code
      }
    })

    /**
     * Gets activated when all form values are checked and validated.
     * Calls to the server to authenticate are made here.
     */
    const handleNext = async () => {

      setButtonsEnabled(false)
      const adminCode = getValues().adminCode.replaceAll('-', '');

      try {
        const codeValue = await trpx.auth.validateSignupToken.query({ type: 'admin', code: adminCode })

        updateStep('AddAdminRoleForm', { data: { ...codeValue, type: 'admin'} })
        increment()
      }
      catch (err) {
        const error = err as TRPCError
        setError(error.code == 'UNAUTHORIZED' ? 'adminCode' : 'root', {
          message: error.message
        })
        setButtonsEnabled(true)
      }
    }

    return (
      <>
        {/* Form */}
        <motion.div className="flex flex-col flex-1 gap-y-8 py-[8vh]" {...omit(props, 'className')}>

          <MaskedInput 
            {...register('adminCode')}
            label='Uw administratorcode'
            tooltipText={<>Deze code bestaat uit 8 cijfers en vindt u in de eerste<br/> mail die u van Skip Scanner ontvangen heeft.</>}
            className='w-60 max-w-[45%] 2xl:max-w-[35%]'
            mask={'9999\\-9999'}
            placeholder='0000-0000'
          />

          {/* Errors */}
          { (!isEmpty(formErrors)) &&
            <div className="space-y-2 w-4/5">
              <Text.Label textScale={'label'} className="text-red-600">
                Er ging iets fout:
              </Text.Label>

              <Text.Paragraph className="text-paragraph-sm text-neutral-600">

                {formErrors?.adminCode && 
                  <>
                    <span className="font-medium">Administratorscode: {' '}</span>  
                    {formErrors.adminCode.message} <br/>
                  </>
                }

                {formErrors?.root && 
                  <>
                    <span className="font-medium">Algemeen: {' '}</span>  
                    {formErrors.root.message} <br/>
                  </>
                }

              </Text.Paragraph>
            </div>
          }

        </motion.div>

        {/* Bottom buttons */}
        <motion.div className="flex gap-x-6 items-center justify-between" {...omit(props, 'className')}>
          <Button 
            btnStyle={'primary'} 
            onClick={decrement}
            btnState={buttonsEnabled ? 'default' : 'disabled'}
          >
            Ga terug
          </Button>
          <Button 
            btnStyle={'primary-filled'} 
            onClick={handleSubmit(handleNext)}
            btnState={buttonsEnabled ? 'default' : 'disabled'}
          >
            Code inwisselen
          </Button>
        </motion.div>
      
      </>
    )
  },
  Output: ({ increment, decrement, updateStep, data, ...props }) => {

    return (
      <>
        {/* Form */}
        <motion.div className="flex flex-col flex-1 gap-y-8 py-[8vh]" {...omit(props, 'className')}>

          <UndrawSoftwareEngineer 
            className='w-3/5'
          />

        </motion.div>

        {/* Bottom buttons */}
        <motion.div className="flex gap-x-6 items-center justify-between" {...omit(props, 'className')}>
          <Button btnStyle={'primary'} onClick={decrement}>Ga terug</Button>
          <Button btnStyle={'primary-filled'} onClick={increment}>Ga verder</Button>
        </motion.div>
      
      </>
    )
  }
} as AddRoleProps

/**
 * The `AddIctRole` object has a form to collect the ICT register code for the user and an output layout
 * which represents the success of the authorization.
 */
export const AddIctRole = {
  Form: ({ increment, decrement, updateStep, data, ...props }) => {

    const [ buttonsEnabled, setButtonsEnabled ] = useState<boolean>(true)

    type AddIctRoleFormValues = {
      ictCode: string
    }

    const AddIctRoleFormSchema = z.object({
      ictCode: z.string()
        .min(8, 'Uw code moet bestaan uit 8 cijfers.').max(9, 'Uw code moet bestaan uit 8 cijfers.')
        .regex(
          new RegExp(`${REGEXP_AUTH_CODE_8_DIGITS}|(\\d{4}-\\d{4})`, 'g'), // Also checks on dash in middle
          'Uw code moet bestaan uit 8 cijfers.'
        )
    })

    const {
      register,
      handleSubmit,
      setValue,
      getValues,
      formState: { errors: formErrors },
      setError
    } = useForm<AddIctRoleFormValues>({ 
      resolver: zodResolver(AddIctRoleFormSchema), 
      defaultValues: {
        ictCode: data.AddIctRoleForm?.code
      }
    })

    /**
     * Gets activated when all form values are checked and validated.
     * Calls to the server to authenticate are made here.
     */
    const handleNext = async () => {

      setButtonsEnabled(false)
      const ictCode = getValues().ictCode.replaceAll('-', '');

      try {
        const codeValue = await trpx.auth.validateSignupToken.query({ type: 'ict', code: ictCode })

        updateStep('AddIctRoleForm', { data: { ...codeValue, type: 'ict' } })
        increment()
      }
      catch (err) {
        const error = err as TRPCError
        setError(error.code == 'UNAUTHORIZED' ? 'ictCode' : 'root', {
          message: error.message
        })
        setButtonsEnabled(true)
      }
    }

    return (
      <>
        {/* Form */}
        <motion.div className="flex flex-col flex-1 gap-y-8 py-[8vh]" {...omit(props, 'className')}>

          <MaskedInput 
            {...register('ictCode')}
            label='Uw ICT-aanmeldcode'
            tooltipText={<>Deze code bestaat uit 8 cijfers en heeft u ontvangen van Skip Scanner.</>}
            className='w-60 max-w-[45%] 2xl:max-w-[35%]'
            mask={'9999\\-9999'}
            placeholder='0000-0000'
          />

          {/* Errors */}
          { (!isEmpty(formErrors)) &&
            <div className="space-y-2 w-4/5">
              <Text.Label textScale={'label'} className="text-red-600">
                Er ging iets fout:
              </Text.Label>

              <Text.Paragraph className="text-paragraph-sm text-neutral-600">

                {formErrors?.ictCode && 
                  <>
                    <span className="font-medium">ICT-aanmeldcode: {' '}</span>  
                    {formErrors.ictCode.message} <br/>
                  </>
                }

                {formErrors?.root && 
                  <>
                    <span className="font-medium">Algemeen: {' '}</span>  
                    {formErrors.root.message} <br/>
                  </>
                }

              </Text.Paragraph>
            </div>
          }

        </motion.div>

        {/* Bottom buttons */}
        <motion.div className="flex gap-x-6 items-center justify-between" {...omit(props, 'className')}>
          <Button 
            btnStyle={'primary'} 
            onClick={decrement}
            btnState={buttonsEnabled ? 'default' : 'disabled'}
          >
            Ga terug
          </Button>
          <Button 
            btnStyle={'primary-filled'} 
            onClick={handleSubmit(handleNext)}
            btnState={buttonsEnabled ? 'default' : 'disabled'}
          >
            Code inwisselen
          </Button>
        </motion.div>
      
      </>
    )
  },
  Output: ({ increment, decrement, updateStep, data, ...props }) => {

    return (
      <>
        {/* Form */}
        <motion.div className="flex flex-col flex-1 gap-y-8 py-[8vh]" {...omit(props, 'className')}>

          <UndrawPairProgramming 
            className='w-3/5'
          />

        </motion.div>

        {/* Bottom buttons */}
        <motion.div className="flex gap-x-6 items-center justify-between" {...omit(props, 'className')}>
          <Button btnStyle={'primary'} onClick={decrement}>Ga terug</Button>
          <Button btnStyle={'primary-filled'} onClick={increment}>Ga verder</Button>
        </motion.div>
      
      </>
    )
  }
} as AddRoleProps

/**
 * The `AddSkscEmployeeRole` object facilitates the process of adding a new SKSC employee role through a two-step procedure; 
 * comprising a form to validate the employee's credentials, and an output layout which indicates the result of the validation.
 */
export const AddSkscEmployeeRole = {
  Form: ({ increment, decrement, updateStep, data, ...props }) => {

    const { query } = useRouter()
    const [ skscEmployeeSignup, setSkscEmployeeSignup ] = useState<boolean>('asSkscEmployee' in query)
    const [ buttonsEnabled, setButtonsEnabled ] = useState<boolean>(true)

    useEffect(() => {
      if(!skscEmployeeSignup) {
        updateStep('AddSkscEmployeeRoleForm', { isActive: false, data: undefined })
        updateStep('AddSkscEmployeeRoleOutput', { isActive: false })
      }
    }, [])

    type AddSkscEmployeeRoleFormValues = {
      password: string
    }

    const AddSkscEmployeeRoleFormSchema = z.object({
      password: z.string().min(1, { message: 'Vul gelieve een Skip Scanner medewerkers-wachtwoord in.'})
    })

    const {
      register,
      handleSubmit,
      getValues,
      formState: { errors: formErrors },
      setError
    } = useForm<AddSkscEmployeeRoleFormValues>({ resolver: zodResolver(AddSkscEmployeeRoleFormSchema) })

    /**
     * Gets activated when all form values are checked and validated.
     * Calls to the server to authenticate are made here.
     */
    const handleNext = async () => {
      
      setButtonsEnabled(false)

      try {
        const jwt = await trpx.auth.validateSkscEmployee.query({ password: getValues().password })

        updateStep('AddSkscEmployeeRoleForm', { data: { jwt } })
        increment()
      }
      catch (err) {
        const error = err as TRPCError
        setError(error.code == 'UNAUTHORIZED' ? 'password' : 'root', {
          message: error.message
        })
        setButtonsEnabled(true)
      }
    }

    return (
      <>
        {/* Form */}
        <motion.div className="flex flex-col flex-1 gap-y-8 py-[8vh]" {...omit(props, 'className')}>

          <PasswordInput 
            {...register('password')}
            label='Uw Skip Scanner medewerkerswachtwoord'
            tooltipText={<>Deze dient u te weten als (gevalideerde) <br/> medewerker van Skip Scanner.</>}
            className='w-3/5 2xl:w-1/2'
            placeholder='Uw wachtwoord'
          />

          {/* Errors */}
          { (!isEmpty(formErrors)) &&
            <div className="space-y-2 w-4/5">
              <Text.Label textScale={'label'} className="text-red-600">
                Er ging iets fout:
              </Text.Label>

              <Text.Paragraph className="text-paragraph-sm text-neutral-600">

                {formErrors?.password && 
                  <>
                    <span className="font-medium">Wachtwoord: {' '}</span>  
                    {formErrors.password.message} <br/>
                  </>
                }

                {formErrors?.root && 
                  <>
                    <span className="font-medium">Algemeen: {' '}</span>  
                    {formErrors.root.message} <br/>
                  </>
                }

              </Text.Paragraph>
            </div>
          }

        </motion.div>

        {/* Bottom buttons */}
        <motion.div className="flex gap-x-6 items-center justify-between" {...omit(props, 'className')}>
          <Button 
            btnStyle={'primary'} 
            onClick={decrement}
            btnState={buttonsEnabled ? 'default' : 'disabled'}
          >
            Ga terug
          </Button>
          <Button 
            btnStyle={'primary-filled'} 
            onClick={handleSubmit(handleNext)}
            btnState={buttonsEnabled ? 'default' : 'disabled'}
          >
            Wachtwoord checken
          </Button>
        </motion.div>
      
      </>
    )
  },
  Output: ({ increment, decrement, updateStep, data, ...props }) => {

    return (
      <>
        {/* Form */}
        <motion.div className="flex flex-col flex-1 gap-y-8 py-[8vh]" {...omit(props, 'className')}>

          <UndrawControlPanel 
            className='w-3/5'
          />

        </motion.div>

        {/* Bottom buttons */}
        <motion.div className="flex gap-x-6 items-center justify-between" {...omit(props, 'className')}>
          <Button btnStyle={'primary'} onClick={decrement}>Ga terug</Button>
          <Button btnStyle={'primary-filled'} onClick={increment}>Ga verder</Button>
        </motion.div>
      
      </>
    )
  }
} as AddRoleProps