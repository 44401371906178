import React, { useState, useEffect, useMemo } from "react";
import moment, { DurationInputArg1, unitOfTime } from 'moment'

type UseTimedMemoProps = <T>(
  callback: () => T, 
  inp: DurationInputArg1, 
  unit: 'seconds' | 'minutes',
  extraDeps?: React.DependencyList 
) => T

/**
 * Creates a memoized value that updates in an interval based on a given duration.
 * Only seconds and minutes are allowed. Runs on entire second or minute.
 * 
 * @template T Type of the returning value of the callback.
 * @param {() T} callback The function whose result you want to memoize.
 * @param {DurationInputArg1} inp The time input, for example, number of seconds.
 * @param {unitOfTime.DurationConstructor} unit The time unit. Can be `seconds` or `minutes`.
 * @returns {T} The memoized result.
 */
export const useTimedMemo:UseTimedMemoProps = (callback, inp, unit, extraDeps) => {

  const updateInterval = moment.duration(inp, unit).asMilliseconds()
  const [tick, setTick] = useState(0);

  useEffect(() => {
      
    // Update the tick every time the updateInterval activates
    const interval = setInterval(() => setTick(prevTick => prevTick + 1), updateInterval)

    return () => clearInterval(interval)

  }, [callback, inp, unit, extraDeps]);

  return useMemo(callback, [tick, extraDeps])
}