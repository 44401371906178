import React, { useEffect, useMemo, useState } from 'react'
import { Text, Button, TextInput, PasswordInput, Select, Tooltip } from '@skip-scanner/ui'
import { isEmpty, omit, update } from 'lodash';
import { useRouter } from 'next/router'
import { Switch } from '@headlessui/react'
import { motion } from 'framer-motion';
import { useTenantStore } from 'lib/stores';
import { z } from "zod";
import { REGEXP_PASSWORD, REGEXP_EMAIL_DOMAIN } from '@skip-scanner/toolkit/lib/validators'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from "react-hook-form";
import type { StepComponentProps } from 'lib/hooks/registration';
import { EmployeeRoles, Gender } from '@skip-scanner/toolkit/types/base';
import { trpx } from 'lib/trpc';
import { signIn } from 'next-auth/react';
import { TRPCError } from '@trpc/server';

/**
 * The first form that is shown to the user on the Register page.
 * Will collect username, password and domainname (so email can be created).
 */
export const UserDataForm:React.FC<StepComponentProps> = ({ increment, decrement, updateStep, data, ...props }) => {

  const [ buttonsEnabled, setButtonsEnabled ] = useState<boolean>(true)
  const { query } = useRouter()
  const [ skscEmployeeSignup, setSkscEmployeeSignup ] = useState<boolean>(false)
  const allowedMailDomains = useTenantStore((state) => state.allowedMailDomains)

  type UserDataFormValues = {
    employeeID: string,
    domainName: string,
    password: string,
    passwordRepeat: string
  }

  const userDataFormSchema = z.object({

    employeeID: z.string({ 
      required_error: 'moet ingevuld zijn.'
    }).min(1, 'moet ingevuld zijn.'),

    password: z.string({ 
      required_error: 'moet ingevuld zijn.'
    }).regex(REGEXP_PASSWORD, 'moet min. 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 speciaal teken bevatten. Ook moet uw wachtwoord min. 8 tekens lang zijn.'),

    domainName: z.string({
      required_error: 'moet gespecificeerd zijn'
    }).regex(REGEXP_EMAIL_DOMAIN, 'moet een geldig format hebben en beginnen met een @-teken.'),

    passwordRepeat: z.string({ 
      required_error: 'moet ingevuld zijn.'
    })

  }).refine(data => data.password === data.passwordRepeat, {
    message: 'uw wachtwoorden komen niet overeen.',
    path: ['password']
  })

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors: formErrors },
    setError
  } = useForm<UserDataFormValues>({ 
    resolver: zodResolver(userDataFormSchema),
    defaultValues: data.UserDataForm && { 
      domainName: data.UserDataForm.domainName,
      employeeID: data.UserDataForm.employeeID,
      password: data.UserDataForm.password,
      passwordRepeat: data.UserDataForm.password
    } 
  })

  // Check if isSkipScannerEmployee
  useEffect(() => setSkscEmployeeSignup('asSkscEmployee' in query), [query])

  /**
   * This function gets called when everything in the form is validated.
   * Here, API calls for validation can be made.
   */
  const handleNext = async () => {
    
    setButtonsEnabled(false)
    const values = getValues()

    try {
      const exists = await trpx.auth.exists.query({ email: `${values.employeeID}${values.domainName}` })

      if(exists) {
        throw new TRPCError({
          code: 'CONFLICT',
          message: `De gebruiker "${values.employeeID}" bestaat al. Als dit uw account is, log dan in.`
        })
      }

      updateStep('UserDataForm', { data: {
        domainName: values.domainName,
        employeeID: values.employeeID,
        password: values.password
      }})
  
      increment()
    }
    catch (err) {
      const error = err as TRPCError
      setError(error.code == 'CONFLICT' ? 'employeeID' : 'root', {
        message: error.message
      })
      setButtonsEnabled(true)
    }
  }

  return (
    <>
      
      {/* Form */}
      <motion.div className="flex flex-col flex-1 gap-y-8 py-[8vh]" {...omit(props, 'className')}>

        {/* Username + domain */}
        <TextInput 
          {...register('employeeID')}
          className='w-4/5 2xl:w-1/2'
          label='Emailadres'
          placeholder='j.janssen'
          tooltipText='Voer hier uw schoolemail in die u doorgaans gebruikt.'
        >
          <TextInput.Dropdown 
            {...register('domainName')}
            label='Domeinnaam'
            options={ skscEmployeeSignup ? [ ...allowedMailDomains.map(domain => `@${domain}`), '@skip-scanner.io' ] : allowedMailDomains.map(domain => `@${domain}`) }
          />
        </TextInput>

        {/* Password */}
        <PasswordInput 
          {...register('password')}
          className='w-3/5 2xl:w-1/2'
          label='Uw nieuwe wachtwoord'
          placeholder='Uw wachtwoord'
          tooltipText={
            <>Uw wachtwoord moet min. 1 hoofdletter, 1 kleine letter, <br/>
            1 cijfer en 1 speciaal teken bevatten. Ook moet deze <br/> 
            min. 8 karakters lang zijn.</>
          }
        />

        {/* Password repeat */}
        <PasswordInput 
          {...register('passwordRepeat')}
          className='w-3/5 2xl:w-1/2'
          label='Wachtwoord herhalen'
          placeholder='Uw wachtwoord'
        />

        {/* Errors */}
        { (!isEmpty(formErrors)) &&
          <div className="space-y-2 w-4/5">
            <Text.Label textScale={'label'} className="text-red-600">
              Er ging iets fout:
            </Text.Label>

            <Text.Paragraph className="text-paragraph-sm text-neutral-600" data-testid="error-container">

              {formErrors?.employeeID && 
                <>
                  <span className="font-medium">Gebruikersnaam: {' '}</span>  
                  {formErrors.employeeID.message} <br/>
                </>
              }

              {formErrors?.password && 
                <>
                  <span className="font-medium">Wachtwoord: {' '}</span>  
                  {formErrors.password.message} <br/>
                </>
              }

              {formErrors?.root && 
                <>
                  <span className="font-medium">Algemeen: {' '}</span>  
                  {formErrors.root.message} <br/>
                </>
              }

            </Text.Paragraph>
          </div>
        }

      </motion.div>

      {/* Bottom buttons */}
      <motion.div className="flex gap-x-6 items-center" {...omit(props, 'className')}>
        <Button 
          btnStyle={'primary-filled'} 
          onClick={handleSubmit(handleNext)} 
          btnState={buttonsEnabled ? 'default' : 'disabled'}
          data-testid="next-button"
        >
          Ga verder
        </Button>
      </motion.div>

    </>
  )
}

/**
 * The form that is shown to collect personal data from the user. This includes:
 * 
 * - First name
 * - Last name
 * - The users gender, of type `Gender`
 * - Which roles the user would like to authenticate for.
 * 
 * These values are saved inside the Register page on the client-side.
 */
export const PersonalDataForm:React.FC<StepComponentProps> = ({ increment, decrement, updateStep, data, ...props }) => {
  
  const [ buttonsEnabled, setButtonsEnabled ] = useState<boolean>(true)

  // Check if is Skip Scanner employee
  const { query } = useRouter()
  const [ skscEmployeeSignup, setSkscEmployeeSignup ] = useState<boolean>('asSkscEmployee' in query)
  const [ isOverflowing, setIsOverflowing ] = useState(false);

  type PersonalDataFormValues = {
    firstName: string,
    prefix: string,
    lastName: string,
    gender: string,
    hasTeacherRole: boolean,
    hasMentorRole: boolean,
    hasAdminRole: boolean,
    hasIctRole: boolean,
    hasSkscEmployeeRole: boolean
  }

  const PersonalDataFormSchema = z.object({
    firstName: z.string({ 
      required_error: 'moet ingevuld zijn.'
    }).min(1, 'moet ingevuld zijn.'),

    prefix: z.string(),

    lastName: z.string({ 
      required_error: 'moet ingevuld zijn.'
    }).min(1, 'moet ingevuld zijn.'),

    gender: z.enum([
      "other", 
      "male", 
      "female", 
      "non-binary", 
      "prefer_not_to_say"
    ]).refine(value => [
      "other", 
      "male", 
      "female", 
      "non-binary", 
      "prefer_not_to_say"
    ].includes(value), {
      message: "moet een van de volgende waardes zijn: Man, Vrouw, Non-binair, Anders, Wil ik liever niet zeggen.",
    }),

    hasTeacherRole: z.boolean(),
    hasMentorRole: z.boolean(),
    hasAdminRole: z.boolean(),
    hasIctRole: z.boolean(),
    hasSkscEmployeeRole: z.boolean(),
  }).superRefine((args, ctx)  => {

    // Check if user is both sksc-employee as another role
    if(args.hasSkscEmployeeRole && ( args.hasAdminRole || args.hasIctRole || args.hasMentorRole || args.hasTeacherRole )) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Een gebruiker kan niet zowel als Skip-Scanner medewerker als een (of meerdere) andere rollen geauthenticeerd worden.',
        path: ['hasSkscEmployeeRole']
      })
    }

    // Check if no roles are selected
    if(!args.hasSkscEmployeeRole && !args.hasAdminRole && !args.hasIctRole && !args.hasMentorRole && !args.hasTeacherRole) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Er moet minimaal één rol geselecteerd worden.',
        path: ['hasSkscEmployeeRole']
      });
    }
  })

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors: formErrors },
    setError
  } = useForm<PersonalDataFormValues>({ 
    resolver: zodResolver(PersonalDataFormSchema), 
    defaultValues: {
      firstName: data.PersonalDataForm?.firstName,
      prefix: data.PersonalDataForm?.prefix,
      lastName: data.PersonalDataForm?.lastName,
      gender: data.PersonalDataForm?.gender,

      hasAdminRole: data.PersonalDataForm?.roles.administrator ?? false,
      hasIctRole: data.PersonalDataForm?.roles.ict ?? false,
      hasMentorRole: data.PersonalDataForm?.roles.mentor ?? false,
      hasTeacherRole: skscEmployeeSignup ? false : data.PersonalDataForm?.roles.teacher ?? true,
      hasSkscEmployeeRole: (data.PersonalDataForm?.roles['sksc-employee'] || skscEmployeeSignup) ? true : false
    }
  })

  /**
   * This function gets called when everything in the form is validated.
   * Here, API calls for validation can be made.
   */
  const handleNext = async () => {

    setButtonsEnabled(false)
    const values = getValues()

    // Save values
    updateStep('PersonalDataForm', { data: {
      firstName: values.firstName,
      lastName: values.lastName,
      prefix: values.prefix,
      gender: values.gender as Gender,
      roles: {
        teacher: values.hasTeacherRole,
        administrator: values.hasAdminRole,
        ict: values.hasIctRole,
        mentor: values.hasMentorRole,
        "sksc-employee": values.hasSkscEmployeeRole        
      } as EmployeeRoles
    }})

    // Try to add SkscEmployeeSignup process
    if(skscEmployeeSignup && values.hasSkscEmployeeRole == true) {
      updateStep('AddSkscEmployeeRoleForm', { isActive: true })
      updateStep('AddSkscEmployeeRoleOutput', { isActive: true })
    }
    else {
      updateStep('AddTeacherRoleForm', { isActive: values.hasTeacherRole })
      updateStep('AddTeacherRoleOutput', { isActive: values.hasTeacherRole })
  
      updateStep('AddAdminRoleForm', { isActive: values.hasAdminRole })
      updateStep('AddAdminRoleOutput', { isActive: values.hasAdminRole })
  
      updateStep('AddIctRoleForm', { isActive: values.hasIctRole })
      updateStep('AddIctRoleOutput', { isActive: values.hasIctRole })
    }

    setTimeout(increment, 500);
  }

  // Check for div overflow
  useEffect(() => {
    function checkOverflow() {
      const element = document.getElementById('leftPane');
      if (element) {
        setIsOverflowing(element.scrollHeight > element.clientHeight);
      }
    }

    // Check overflow initially
    checkOverflow();

    // Recheck overflow whenever the window is resized
    window.addEventListener('resize', checkOverflow);

    return () => {
      // Clean up the event listener when the component is unmounted
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  return (
    <>
      {/* Form */}
      <motion.div className="flex flex-col flex-1 gap-y-8 py-[8vh]" {...omit(props, 'className')}>

        {/* First and last name */}
        <div className="space-x-8 flex flex-row">
          <TextInput 
            {...register('firstName')}
            label='Voornaam'
            placeholder='Uw voornaam'
            className='w-[45%] 2xl:w-[35%]'
          />

          <TextInput 
            {...register('prefix')}
            label='Tussenvoegsel'
            placeholder='Optioneel'
            className='w-[45%] 2xl:w-[35%]'
          />
        </div>

        <div className="space-x-8 flex flex-row">

          <TextInput
            {...register('lastName')}
            label='Achternaam'
            placeholder='Uw achternaam'
            className='w-[45%] 2xl:w-[35%]'
          />

          {/* Gender */}
          <Select 
            {...register('gender')}
            className='w-[45%] 2xl:w-[35%]'
            label='Uw geslacht'
            options={[
              { id: 'male', displayName: 'Man'},
              { id: 'female', displayName: 'Vrouw' },
              { id: 'non-binary', displayName: 'Non-binair'},
              { id: 'other', displayName: 'Anders'},
              { id: 'prefer_not_to_say', displayName: 'Wil ik liever niet zeggen'}
            ]}
          />
        </div>

        {/* Roles */}
        <div style={{  marginTop: isOverflowing ? '0px' : '2rem' }}>
          <label className="block font-medium leading-6 text-neutral-900 relative">
            <Text.Label textScale={'label'} textDisplay={'inline'}>
              Kies uw gebruikersrollen  
            </Text.Label>
            <Tooltip tooltipText={<>Voor elke rol zult u een korte authenticatie moeten uitvoeren, <br/> om te bewijzen dat u ook daadwerkelijk deze rol mag toekennen.</>} className="ml-1 bottom-[2px]"/>

          </label>
          <div className="relative mt-4 flex gap-x-8">

            <Switch
              checked={watch('hasTeacherRole')}
              onChange={(state) => setValue('hasTeacherRole', state)}
              as={React.Fragment}
              data-testid="toggle-teacherRole"
            >
              {({ checked }) => (
                <Button btnStyle={checked ? 'primary-filled' : 'primary'} className='!w-36'>Leraar</Button>
              )}
            </Switch>

            <Switch
              checked={watch('hasMentorRole')}
              onChange={(state) => setValue('hasMentorRole', state)}
              as={React.Fragment}
              data-testid="toggle-mentorRole"
            >
              {({ checked }) => (
                <Button btnStyle={checked ? 'primary-filled' : 'primary'} className='!w-36'>Mentor</Button>
              )}
            </Switch>

            <Switch
              checked={watch('hasAdminRole')}
              onChange={(state) => setValue('hasAdminRole', state)}
              as={React.Fragment}
              data-testid="toggle-adminRole"
            >
              {({ checked }) => (
                <Button btnStyle={checked ? 'primary-filled' : 'primary'} className='!w-36'>Administrator</Button>
              )}
            </Switch>

            <Switch
              checked={watch('hasIctRole')}
              onChange={(state) => setValue('hasIctRole', state)}
              as={React.Fragment}
              data-testid="toggle-ictRole"
            >
              {({ checked }) => (
                <Button btnStyle={checked ? 'primary-filled' : 'primary'} className='!w-36'>ICT</Button>
              )}
            </Switch>

            {skscEmployeeSignup && 
              <Switch
                checked={watch('hasSkscEmployeeRole')}
                onChange={(state) => setValue('hasSkscEmployeeRole', state)}
                as={React.Fragment}
                data-testid="toggle-skscEmployeeRole"
              >
                {({ checked }) => (
                  <Button btnStyle={checked ? 'primary-filled' : 'primary'}>Sksc medewerker</Button>
                )}
              </Switch>
            }

          </div>
        </div>

        {/* Errors */}
        { (!isEmpty(formErrors)) &&
          <div className="space-y-2 w-4/5">
            <Text.Label textScale={'label'} className="text-red-600">
              Er ging iets fout:
            </Text.Label>

            <Text.Paragraph className="text-paragraph-sm text-neutral-600">

              {formErrors?.firstName && 
                <>
                  <span className="font-medium">Voornaam: {' '}</span>  
                  {formErrors.firstName.message} <br/>
                </>
              }

              {formErrors?.lastName && 
                <>
                  <span className="font-medium">Achternaam: {' '}</span>  
                  {formErrors.lastName.message} <br/>
                </>
              }

              {formErrors?.root && 
                <>
                  <span className="font-medium">Algemeen: {' '}</span>  
                  {formErrors.root.message} <br/>
                </>
              }

              {formErrors?.hasSkscEmployeeRole && 
                <>
                  <span className="font-medium">Rollen: {' '}</span>  
                  {formErrors.hasSkscEmployeeRole.message} <br/>
                </>
              }

            </Text.Paragraph>
          </div>
        }

      </motion.div>

      {/* Bottom buttons */}
      <motion.div className="flex gap-x-6 items-center justify-between" {...omit(props, 'className')}>
        <Button 
          btnStyle={'primary'} 
          onClick={decrement} 
          btnState={buttonsEnabled ? 'default' : 'disabled'}
          data-testid="return-button"
        >
          Ga terug
        </Button>
        <Button 
          btnStyle={'primary-filled'} 
          onClick={handleSubmit(handleNext)} 
          btnState={buttonsEnabled ? 'default' : 'disabled'}
          data-testid="next-button"
        >
          Ga verder
        </Button>
      </motion.div>
    </>
  )
}

/**
 * The output that is shown which summarizes the user data that is collected. Here, the user can
 * check if everything is correct and optionally go back to change their data.
 */
export const CompleteDataOutput:React.FC<StepComponentProps> = ({ increment, decrement, updateStep, data, ...props }) => {

  const router = useRouter()
  const [ registerSuccess, setRegisterSuccess ] = useState<boolean>(false) 
  const [ buttonsEnabled, setButtonsEnabled ] = useState<boolean>(true)

  /**
   * This function gets called when everything in the form is validated.
   * Here, API calls for validation can be made.
   */
  const handleNext = async () => {

    setButtonsEnabled(false)

    if(!data.PersonalDataForm || !data.UserDataForm) {
      setError('root', {
        message: 'Er is geen persoonlijke data of gebruikersdata gevonden. Uw sessie is waarschijnlijk verlopen. U wordt doorgestuurd om opnieuw te beginnen.'
      })
      setTimeout(() => {
        window.localStorage.clear()
        router.reload()
      }, 5000);
      return
    }

    try {

      const newEmployee = await trpx.auth.register.mutate({
        ...data.UserDataForm,
        roles: data.PersonalDataForm.roles,
        personal: omit(data.PersonalDataForm, 'roles'),
        adminJWT: data.AddAdminRoleForm?.jwt,
        ictJWT: data.AddIctRoleForm?.jwt,
        teacherJWT: data.AddTeacherRoleForm?.jwt,
        skscEmployeeJWT: data.AddSkscEmployeeRoleForm?.jwt
      })

      console.log(newEmployee)

      setRegisterSuccess(true)
      setTimeout(async () => {

        window.localStorage.removeItem('registration.filteredSteps')
        window.localStorage.removeItem('registration.activeStepIdx')
        window.localStorage.removeItem('registration.stepData')

        await signIn('credentials', {
          ...newEmployee,
          redirect: false
        })

        router.push('/dashboard')
      }, 3000);
    }
    catch (err) {
      const error = err as TRPCError
      setError('root', {
        message: error.message
      })
      setButtonsEnabled(true)
    }
  }

  const {
    formState: { errors: formErrors },
    setError
  } = useForm()

  return (
    <>
      {/* Form */}
      <motion.div className="flex flex-col flex-1 gap-y-8 py-[8vh]" {...omit(props, 'className')}>

        <dl className="divide-y divide-neutral-200 2xl:w-2/3">

          {/* Email address */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className='select-none'>
              <Text.Label className='text-sm font-medium leading-6'>Emailadres</Text.Label>
            </dt>
            <dd className="mt-1 sm:col-span-2 sm:mt-0">
              <Text.Paragraph className='text-neutral-600 text-sm'>
                {data.UserDataForm?.employeeID}{data.UserDataForm?.domainName}
              </Text.Paragraph>
            </dd>
          </div>

          {/* Full name */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className='select-none'>
              <Text.Label className='text-sm font-medium leading-6'>Volledige naam</Text.Label>
            </dt>
            <dd className='col-span-2'>
              <Text.Paragraph className='text-neutral-600 text-sm'>
                {data.PersonalDataForm?.firstName}
                {' '}
                {data.PersonalDataForm?.prefix ?? ''}
                {' '}
                {data.PersonalDataForm?.lastName}
              </Text.Paragraph>
            </dd>
          </div>

          {/* Gender */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className='select-none'>
              <Text.Label className='text-sm font-medium leading-6'>Geslacht</Text.Label>
            </dt>
            <dd className="mt-1 sm:col-span-2 sm:mt-0">
              <Text.Paragraph className='text-neutral-600 text-sm'>
                {
                  data.PersonalDataForm?.gender == 'male' ? 'Man' :
                  data.PersonalDataForm?.gender == 'female' ? 'Vrouw' :
                  data.PersonalDataForm?.gender == 'non-binary' ? 'Non-binair' :
                  data.PersonalDataForm?.gender == 'other' ? 'Anders' : 
                  data.PersonalDataForm?.gender == 'prefer_not_to_say' && 'Wil ik liever niet zeggen'
                }
              </Text.Paragraph>
            </dd>
          </div>

          {/* Roles */}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className='select-none'>
              <Text.Label className='text-sm font-medium leading-6'>Gebruikersrollen</Text.Label>
            </dt>
            <dd className="mt-1 sm:col-span-2 sm:mt-0">
              <ul className='list-outside ml-4 [&>*]:pl-1 marker:text-neutral-400 list-decimal text-neutral-600 text-sm'>

                {data.PersonalDataForm?.roles.administrator && <li>Administrator</li>} 
                {data.PersonalDataForm?.roles.ict && <li>ICT-medewerker</li>} 
                {data.PersonalDataForm?.roles.teacher && <li>Leraar</li>}
                {data.PersonalDataForm?.roles.mentor && <li>Mentor</li>} 
                {data.PersonalDataForm?.roles['sksc-employee'] && <li>Skip Scanner medewerker</li>} 

              </ul>
            </dd>
          </div>

        </dl>

        {/* Register success */}
        {registerSuccess &&
          <div className="space-y-2 w-4/5">
            <Text.Label textScale={'label'}>
              Uw account is succesvol aangemaakt
            </Text.Label>
            <Text.Paragraph className="text-paragraph-sm text-neutral-600">
              U wordt over enkele seconden automatisch ingelogd.
            </Text.Paragraph>
          </div>
        }

        {/* Errors */}
        { (!isEmpty(formErrors)) &&
          <div className="space-y-2 w-4/5">
            <Text.Label textScale={'label'} className="text-red-600">
              Er ging iets fout:
            </Text.Label>

            <Text.Paragraph className="text-paragraph-sm text-neutral-600">

              {formErrors?.root && 
                <>
                  <span className="font-medium">Algemeen: {' '}</span>  
                  {formErrors.root.message} <br/>
                </>
              }

            </Text.Paragraph>
          </div>
        }

      </motion.div>

      {/* Bottom buttons */}
      <motion.div className="flex gap-x-6 items-center justify-between" {...omit(props, 'className')}>
        <Button 
          btnStyle={'primary'} 
          onClick={decrement} 
          btnState={buttonsEnabled ? 'default' : 'disabled'}
        >
          Ga terug
        </Button>
        <Button 
          btnStyle={'primary-filled'} 
          onClick={handleNext} 
          btnState={buttonsEnabled ? 'default' : 'disabled'}
        >
          Account aanmaken
        </Button>
      </motion.div>
    </>
  )
}