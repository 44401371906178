import React, { useMemo } from "react";
import { Breadcrumb, Text } from "@skip-scanner/ui";
import { NavBar, NavLink, Main } from "components";
import { BreakpointOverlayProvider, HardwareScanProvider, ScheduleSyncProvider } from 'lib/providers'
import { useLocalStorage, useIsClient, useWindowSize } from 'usehooks-ts'
import Head from "next/head";
import { useSession } from 'next-auth/react'
import { 
  CalendarIcon ,
  ChartBarSquareIcon,
  HomeIcon,
  UserIcon,
  UserGroupIcon,
  DeviceTabletSearchIcon,
  DatabaseIcon,
  BellAlertIcon,
  ClipboardDocumentListIcon,
} from '@skip-scanner/ui/icons'
import { useRouter } from "next/router";
import { AnimatePresence } from "framer-motion";
import { startCase } from "lodash";

/**
 * The props that need to be passed as a type to `AppLayout`.
 * @property {React.ReactNode} [children] - React nodes (elements/components) to be rendered as the main content inside the layout.
 * @property {string} title - The title for the layout or page.
 */
type AppLayoutProps = {
  children?: React.ReactNode,
  title: string,
  overflow?: boolean
}

/**
 * Represents the main layout of the application, including a dynamic navigation bar 
 * and the main content area. The navigation bar can be expanded or collapsed 
 * based on the user's preference, which is stored in local storage.
 * 
 * The layout also ensures that the UI components are rendered only on the client side.
 * 
 * @component
 * @param {AppLayoutProps} props - Properties passed down to the AppLayout component.
 * @returns {ReactElement} Returns the main layout of the application.
 */
export const AppLayout:React.FC<AppLayoutProps> = ({ children, title, overflow = true }) => {

  const router = useRouter()
  const isClient = useIsClient()
  const { status } = useSession()

  // If the navbar is expanded
  const [ expanded, setExpanded ] = useLocalStorage<boolean>('ui-navbar-expanded', true)

  const pathSegments = useMemo(() => {
    return router.pathname.split('/').filter(Boolean)
  }, [router])

  if(!isClient) return null;

  return (
    <>
      <Head>
        <title>{startCase(pathSegments[pathSegments.length - 1])} | Skip Scanner</title>
        <link rel='icon' href='/favicon.ico' />
      </Head>

      <BreakpointOverlayProvider>
        <HardwareScanProvider>
          <ScheduleSyncProvider>

            <NavBar expanded={expanded} setExpanded={setExpanded}>

              {/* Dashboard for all */}
              <NavLink 
                icon={HomeIcon}
                action={'/dashboard'} 
                btnStyle={'nav'}
                btnSize={expanded ? 'large' : 'small'}
              >
                Dashboard
              </NavLink>

              {/* Teacher schedule */}
              <NavLink 
                icon={CalendarIcon}
                action={'/lesrooster'} 
                btnSize={expanded ? 'large' : 'small'}
                btnStyle={'nav'}
                allowedRoles={['teacher']}
              >
                Lesrooster
              </NavLink>

              {/* Scan page */}
              <NavLink 
                icon={ClipboardDocumentListIcon}
                action={'/scannen'} 
                btnSize={expanded ? 'large' : 'small'}
                btnStyle={'nav'}
                allowedRoles={['teacher', 'mentor', 'administrator']}
              >
                Scannen
              </NavLink>

              {/* Insights */}
              {/* <NavLink 
                icon={ChartBarSquareIcon}
                action={'/inzichten'} 
                btnStyle={'nav'}
                btnSize={expanded ? 'large' : 'small'}
                allowedRoles={['administrator', 'mentor', 'teacher']}
              >
                Inzichten
              </NavLink> */}

              {/* Database management */}
              <NavLink 
                icon={DatabaseIcon}
                action={'/database'} 
                btnStyle={'nav'}
                btnSize={expanded ? 'large' : 'small'}
                allowedRoles={['sksc-employee']} // Add ICT role
              >
                Database
              </NavLink>

              {/* Device management */}
              <NavLink 
                icon={DeviceTabletSearchIcon}
                action={'/apparaten'} 
                btnStyle={'nav'}
                btnSize={expanded ? 'large' : 'small'}
                allowedRoles={['sksc-employee']} // Add ICT role
              >
                Apparaten
              </NavLink>

              {/* Notification centre */}
              <NavLink 
                icon={BellAlertIcon}
                action={'/notificaties'} 
                btnStyle={'nav'}
                btnSize={expanded ? 'large' : 'small'}
              >
                Notificaties
              </NavLink>

              {/* Account for all */}
              <NavLink 
                icon={UserIcon}
                action={'/account'} 
                btnStyle={'nav'}
                btnSize={expanded ? 'large' : 'small'}
              >
                Mijn account
              </NavLink>

            </NavBar> 
        
            <Main 
              expanded={!expanded}
              overflow={overflow}
            >
              <AnimatePresence>
                {status == 'authenticated' && <>

                  <Breadcrumb 
                    key={'breadcrumb'}
                    className="mb-2 mt-4"
                    crumbStyle={'chevron'}
                    pathSegments={pathSegments}
                  />

                  <Text.Display textColor={'gradient-to-b'} className='text-display-md from-primary-darker to-primary-light'>
                    {title}.
                  </Text.Display>

                  <hr className='mt-4 mb-8 border-neutral-100 border'/>


                  {children}

                </>}
              </AnimatePresence>
            </Main>

          </ScheduleSyncProvider>
        </HardwareScanProvider>      
      </BreakpointOverlayProvider>
    </>
  )
}