import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { Button, Text, Spinner, Card } from '@skip-scanner/ui'
import { useTimedMemo, useWeekDates } from 'lib/hooks'
import { useScheduleStore } from 'lib/stores'
import { cva } from 'class-variance-authority'
import { schedule } from 'lib/modules'
import { Appointment } from '@skip-scanner/toolkit/database'
import { useSetAtom } from 'jotai'
import { overlayAtoms } from 'lib/atoms'
import { AnimatePresence, motion } from 'framer-motion'
import { upperFirst } from 'lodash'
import { AcademicCapIcon, ClockIcon } from '@heroicons/react/24/outline'
import { useRouter } from 'next/router'

//#region Helper functions

  type AppointmentCardProps = {
    appointment: Appointment,
    index: number
  }

  const AppointmentCard: React.FC<AppointmentCardProps> = ({ appointment, index }) => {

    const { status, isScannable } = useTimedMemo(() => ({
      status: schedule.getStatus(appointment),
      isScannable: schedule.isScannable(appointment)
    }), 60, 'seconds', [appointment])

    const humanReadableStatus = useMemo(() => {
      switch(status) {
        case 'upcoming': return 'Toekomstig';
        case 'prefix_time': return 'Inlooptijd';
        case 'active': return 'Nu actief';
        case 'suffix_time': return 'Uitlooptijd';
        case 'expired': return 'Recent verlopen';
        case 'cancelled': return 'Geannuleerd';
      }
    }, [status])
    
    const router = useRouter()
    const setAppointmentOverlay = useSetAtom(overlayAtoms.appointmentInfo)

    /**
     * This function is responsible for handling the activation of an appointment.
     * It can do one of 2 things:
     * 
     * - If the appointment is scannable, it will redirect the user to the `/scannen` page,
     * with the appointment ID as a query parameter.
     * - If the appointment is not scannable, it will set the `appointmentInfoOverlayAtom` to the
     * appointment ID, which will then be used to display the appointment overlay.
     */
    const activateAppointment = () => {
      if (isScannable) {
        router.replace(`/scannen?aptmt=${appointment.id}`)
        return
      }

      setAppointmentOverlay(appointment.id);
      return;
    }

    //#region Appointment card styling

      const cardStyles = {
        wrapper: cva('absolute flex', {
          variants: {
            width: {
              '1': 'md:w-[20%] col-start-1',
              '2': 'md:w-[25%] col-start-2',
              '3': 'md:w-[33.3%] col-start-3',
              '4': 'md:w-[50%] col-start-4',
              '5': 'md:w-[100%] col-start-5',
              'hidden': 'hidden'
            }
          }
        }),
        card: cva('group flex flex-col relative overflow-y-auto m-1 border-[1.5px] bg-neutral-50 border-neutral-200 w-full p-1 text-xs leading-4 rounded-[6px] cursor-pointer transition-colors select-none', {
          variants: {
            status: {
              'upcoming': 'hover:bg-navy-lighter/20 hover:border-navy-light/50',
              'prefix_time': 'hover:bg-primary-light/20 hover:border-primary-light/50',
              'active': 'hover:bg-primary-light/20 hover:border-primary-light/50',
              'suffix_time': 'hover:bg-primary-light/20 hover:border-primary-light/50',
              'expired': 'hover:bg-neutral-400/20 hover:border-neutral-400/50',
              'cancelled': 'hover:bg-red-600/20 hover:border-red-600/50',
            },
          }
        }),
        indicator: cva('inline-block rounded-[4px] px-[5px] py-px', {
          variants: {
            status: {
              'upcoming': 'bg-navy-lighter/80 text-white',
              'prefix_time': 'bg-primary-lighter text-white',
              'active': 'bg-primary-lighter text-white',
              'suffix_time': 'bg-primary-lighter text-white',
              'expired': 'bg-neutral-500/80 text-white',
              'cancelled': 'bg-red-600/80 text-white',
            },
          }
        })
      }

      /**
       * Calculates the width of an appointment based on the current day.
       * @param appointment - The appointment object.
       * @returns The width of the appointment as a string, or 'hidden' if the current day is not in the list of days.
       */
      const width = useMemo(() => {
        const days = [1, 2, 3, 4, 5]
        const currentDay = moment(appointment.time.start).isoWeekday()

        if(days.includes(currentDay)) return `${currentDay}` as '1' | '2' | '3' | '4' | '5'
        return 'hidden' as 'hidden'
      }, [appointment.time.start])

      const cardBodyRef = useRef<HTMLDivElement>(null)
      const [dimensions, setDimensions] = useState({ top: 1, height: 1 });

      useEffect(() => {
        const startHour = moment(appointment.time.start).format('HH:mm');
        const endHour = moment(appointment.time.end).format('HH:mm');
        const top = calculateTop(startHour); // Assumes calculateTop is adapted to work here
        const height = calculateHeight(startHour, endHour); // Assumes calculateHeight is adapted to work here
  
        setDimensions({ top, height });
      }, [appointment, cardBodyRef.current]);

    //#endregion

    return (
      <motion.div 
        className={cardStyles.wrapper({ width })}
        ref={cardBodyRef}
        initial={{ 
          opacity: 0,
          scale: 0.8,
        }}
        animate={{ 
          opacity: 1,
          scale: 1,
        }}
        style={{ 
          top: dimensions.top,
          height: dimensions.height
        }}
        transition={{ 
          duration: 0.35,
          delay: index * 0.08,
          type: 'spring',
        }}
      >
        <motion.div 
          className={cardStyles.card({ status })} 
          whileHover={{ scale: 1.035 }}
          whileTap={{ scale: 0.95 }}
          onClick={activateAppointment}
        >
          <Text.Label className='text-label-sm font-medium mt-1 ml-0.5'>
            { appointment.subjects.length > 0 
              ? `${appointment.subjects.map(sub => upperFirst(sub)).join(', ')}` 
              : `${upperFirst(appointment.type)}`
            }{`, ${moment(appointment.time.start).format(`HH:mm`)}`}
          </Text.Label>

          <hr className='mb-1 mt-1.5 border-neutral-200 border-[1px]'/>
          <Text.Paragraph className='text-paragraph-xs text-neutral-700 font-normal'>
            <AcademicCapIcon className='w-[14px] h-[14px] inline-block relative bottom-0.5 left-0.5 mr-2 text-neutral-700' />

            {Object.entries(appointment.attendance.registrations).length > 0 
              ? Object.entries(appointment.attendance.registrations).length
              : 'Geen'
            } leerlingen.
          </Text.Paragraph>

          <Text.Paragraph className='text-paragraph-xs mt-1'>
            <ClockIcon className='w-[14px] h-[14px] inline-block relative bottom-0.5 left-0.5 mr-2 text-neutral-500' />

            <span className={cardStyles.indicator({ status })}>
              {humanReadableStatus}
            </span>
          </Text.Paragraph>

        </motion.div>
      </motion.div>
    )
  }

  /**
   * `calculateTop` is a function that calculates the vertical offset position of a timeslot
   * in relation to the reference element `bodyRef`.
   *
   * @param {string} startElemID - The ID of the HTML element, which corresponds to the start time of an event.
   * @param {React.RefObject<HTMLDivElement>} ref - The reference to the HTML element, which is the parent of the timeslot elements.
   * @returns {number} Returns the vertical offset position as a number. Returns 1 if any of the DOM elements are not found.
   */
  const calculateTop = (startElemID: string) => {

    /**
     * The element that has the highest vertical position any appointment
     * can have in the calendar. It is the direct parent of the time slots.
     */
    const cardWrapper = document.getElementById('card-wrapper')

    /**
     * The ID of the vertical grid div corresponding to the start time of the time slot,
     * aka. the top of the card.
     */
    const cardTop = document.getElementById(startElemID)

    if(!cardWrapper || !cardTop ) return 1;
    return cardTop.offsetTop - cardWrapper.offsetTop
  }

  /**
   * `calculateHeight` is a function that calculates the height of a time slot based on
   * the vertical positions of the start and end time elements in relation to the reference element `bodyRef`.
   *
   * @function
   * @param {string} startElemID - The ID of the HTML element, which corresponds to the start time of the time slot.
   * @param {string} endElemID - The ID of the HTML element, which corresponds to the end time of the time slot.
   * 
   * @returns {number} Returns the height of the time slot as a number. Returns 1 if any of the DOM elements are not found.
   */
  const calculateHeight = (startElemID: string, endElemID: string) => {

    /**
     * The element that has the highest vertical position any appointment
     * can have in the calendar. It is the direct parent of the time slots.
     */
    const cardWrapper = document.getElementById('card-wrapper')

    /**
     * The ID of the vertical grid div corresponding to the start time of the time slot,
     * aka. the top of the card.
     */
    const cardTop = document.getElementById(startElemID)

    /**
     * The ID of the vertical grid div corresponding to the end time of the time slot,
     * aka. the bottom of the card.
     */
    const cardBottom = document.getElementById(endElemID)

    if(!cardWrapper || !cardTop || !cardBottom) return 1

    let marginTop = cardTop.offsetTop - cardWrapper.offsetTop;
    return (cardBottom.offsetTop - cardWrapper.offsetTop) - marginTop
  }

//#endregion

export const Calendar:React.FC<{ week: number }> = ({ week }) => {

  const { appointments, status } = useScheduleStore()
  const filteredAppointments = appointments.filter(aptmt => moment(aptmt.time.start).week() === week)
  
  const { weekDates, timeDates } = useWeekDates({ 
    weekNumber: week, 
    includeWeekends: false, 
    startTime: '08:00', 
    endTime: '19:00' 
  })

  return (
    <div className='border-gray-200 border-2 w-auto shadow-lg rounded-lg h-[80vh] overflow-hidden relative'>

      {/* Head */}
      <div className='bg-gray-50 flex flex-row divide-x divide-gray-300 border-b-gray-300 border-b text-center h-16 justify-center items-center'>

        <div className='w-[4.25rem] md:w-[3.75rem] py-2 inline-block'></div>

        {weekDates.map(({ date, isToday }) => {

          if(moment(date).isoWeekday() === 6 || moment(date).isoWeekday() === 7 ) return;

          return (
            <div className={`${isToday ? 'w-full' : 'hidden'} md:w-1/5 md:inline-block py-2 font-semibold`} key={date.getTime()}>
              <span>{`${upperFirst(moment(date).format('ddd'))}`}</span>
              <span className={`mx-1 ${isToday ? 'inline-flex items-center justify-center h-8 w-8 rounded-full bg-primary font-semibold text-white' : ''}`}>
                {moment(date).format('DD')}
              </span>
              <span>{`${moment(date).format('MMM')}`}</span>
            </div>
          )
        })}

        <div className='w-[9.5px] py-2 inline-block !border-l-0 !border-r-0'></div>

      </div>

      {/* Loading indicator */}
      <AnimatePresence>
        {status !== 'idle' && (
          <div className="mt-16 ml-14 inset-0 absolute z-10 flex justify-center items-center flex-col">

            <Card 
              initial={{ opacity: 0, scale: 0.7 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.35, type: 'spring' }}
              className='flex justify-center items-center flex-col px-12 py-8 border-primary-lighter/50 border-[1.5px] rounded-2xl'
            >
              <Spinner className='h-20 text-primary mb-8 mt-12' />

              <Text.Heading className='text-heading-lg'>
                Een moment geduld a.u.b.
              </Text.Heading>

              <Text.Paragraph className='text-paragraph-md text-neutral-500 max-w-md mt-2 text-center'>
                {
                  status === 'syncing' 
                    ? <>Uw afspraken worden voor het eerst gesynchroniseerd met Zermelo. Dit kan enkele momenten duren.</> 
                    : <>Uw afspraken worden ververst om u de meest recente gegevens te kunnen presenteren.</>
                }
              </Text.Paragraph>
            </Card>

          </div>
        )}
      </AnimatePresence>

      {/* Body */}
      <div className={`flex flex-auto max-h-[80vh] scrollbar-thin overflow-y-scroll transition-all ${status !== 'idle' && 'blur-[1px]'}`}>

        <div className='sticky left-0 z-10 w-14 flex-col flex bg-white ring-1 ring-gray-300' style={{ height: `${timeDates.length * 7.5}rem` }}/>
        <div className={`grid flex-auto grid-cols-1 grid-rows-1 relative`}>

          {/* Horizontal lines */}
          <div 
            id='card-wrapper'
            className='col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-300'  
            style={{ gridTemplateRows: 'repeat(48, minmax(7.5rem, 1fr))' }}
          >
            <div className='row-end-1 h-7'></div>

            {timeDates.map((time) => {

              const startMoment = moment(time, 'HH:mm');
              const endMoment = moment(time, 'HH:mm').endOf('hour');

              const minutes: moment.Moment[] = [];
              let currentMinute = startMoment;
              while (currentMinute.isBefore(endMoment)) {
                // if(currentMinute.minute() == 0)
                minutes.push(moment(currentMinute));
                currentMinute.add(10, 'minutes');
              }

              return (
                <div key={time.toDate().getTime()}>
                  <label className='block sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400'>{time.format('HH:mm')}</label>
                  <div className='flex flex-col justify-evenly h-full -mt-2.5'>
  
                    {minutes.map((minute) => { 
                      return <div key={minute.toDate().getTime()} className='h-1/6' id={`${minute.format('HH:mm')}`}></div> 
                    })}
  
                  </div>
                </div>
              )
            })}
          </div>

          {/* Vertical lines */}
          <div className='col-start-1 col-end-2 row-start-1 hidden grid-cols-5 grid-rows-1 divide-x divide-gray-300 sm:grid sm:grid-cols-5'
            style={{ height: `${timeDates.length * 7.5}rem` }}>
            <div className='col-start-1 col-end-5 md:col-end-1 row-span-full' />
            <div className='hidden md:block col-start-2 row-span-full' />
            <div className='hidden md:block col-start-3 row-span-full' />
            <div className='hidden md:block col-start-4 row-span-full' />
            <div className='hidden md:block col-start-5 row-span-full' />
          </div>

          {/* Appointments */}
          <div className='col-start-1 row-start-1 grid grid-cols-1 md:grid-cols-5 relative'>
            {status === 'idle' && filteredAppointments.map((aptmt, idx) => 
              <AppointmentCard 
                appointment={aptmt} 
                index={idx}
                key={aptmt.id}
              /> 
            )}
          </div>

        </div>

      </div>

    </div>
  )
}