import { useState, useEffect, useCallback, useMemo } from "react";
import { useLocalStorage } from "./localstorage";
import { Gender, EmployeeRoles } from '@skip-scanner/toolkit/types/base';
import { HTMLMotionProps } from 'framer-motion';
import { UserDataForm, PersonalDataForm, CompleteDataOutput } from 'components/auth/register'
import { AddTeacherRole, AddAdminRole, AddIctRole, AddSkscEmployeeRole } from 'components/auth/tokens'
import Link from 'next/link';

export type StepComponentProps = {
  updateStep: <S extends StepName>(stepName: S, updatedStepData: UpdateStepData<S>) => void,
  increment: () => void,
  decrement: () => void,
  data: StepData
} & HTMLMotionProps<'div'>

export type RegisterStep = {
  name: StepName,
  desc: React.ReactNode,
  component: React.FC<StepComponentProps>,
  before?: StepName | null,
  after?: StepName | null,
  isActive: boolean,
}

/**
 * This function takes an array of steps with their respective properties and returns a new array with only the active steps, sorted based on the 'before' and 'after' properties of the steps. 
 * The sorting also handles incomplete information by adding steps with undefined placement at the end of the sorted array.
 *
 * @param {RegisterStep[]} allSteps - Array containing all the steps with their respective properties.
 * @returns {RegisterStep[]} - Array containing only the active steps sorted based on the before and after properties.
 */
const getSortedActiveSteps = (allSteps: RegisterStep[]): RegisterStep[] => {

  // Creating a map to look up steps based on their name for quick access
  const stepMap = new Map<string, RegisterStep>();
  
  // Variables to store the names of the first and last steps
  let firstStepName = '';
  let lastStepName = '';

  // Populating stepMap and identifying the first and last steps
  allSteps.forEach((step) => {
    stepMap.set(step.name, step);
    if (step.before === null) firstStepName = step.name;
    if (step.after === null) lastStepName = step.name;
  });

  // Array to store the sorted active steps
  const sortedSteps: RegisterStep[] = [];
  
  // Variable to store the name of the current step during the sorting process
  let currentStepName = firstStepName;

  // Loop to add active steps to the sorted array following the 'after' property chain starting from the first step
  while (currentStepName) {
    const currentStep = stepMap.get(currentStepName);
    if (currentStep && currentStep.isActive) {
      sortedSteps.push(currentStep);
    }
    currentStepName = currentStep?.after ?? '';
  }

  // Loop to add remaining active steps, which couldn't be placed based on the before and after properties, at the end of the sorted array
  allSteps.forEach((step) => {
    if (step.isActive && !sortedSteps.includes(step)) {
      sortedSteps.push(step);
    }
  });

  return sortedSteps;
};

/**
 * All the valid Steps / stepNames that can be used. First initialized as an array, so runtime checking can be done as well,
 * next to static type parsing.
 */
const validStepNames = [
  'UserDataForm',
  'PersonalDataForm',
  'AddTeacherRoleForm',
  'AddTeacherRoleOutput',
  'AddAdminRoleForm',
  'AddAdminRoleOutput',
  'AddIctRoleForm',
  'AddIctRoleOutput',
  'AddSkscEmployeeRoleForm',
  'AddSkscEmployeeRoleOutput',
  'CompleteDataOutput'
] as const;

type StepName = typeof validStepNames[number];

/**
 * The data that gets saved for each different form, and in what way it gets stored and formed.
 */
type StepData = {
  UserDataForm?: {
    employeeID: string,
    domainName: string,
    password: string,
  },
  PersonalDataForm?: {
    firstName: string,
    prefix: string,
    lastName: string,
    gender: Gender,
    roles: EmployeeRoles
  },
  AddTeacherRoleForm?: {
    jwt: string,
    employeeCode: string,
    created: string,
    timeout: number,
    accessToken: string
  },
  AddAdminRoleForm?: {
    jwt: string,
    code: string,
    type: 'admin'
  },
  AddIctRoleForm?: {
    jwt: string,
    code: string,
    type: 'ict'
  },
  AddSkscEmployeeRoleForm?: {
    jwt: string
  }
}

/**
 * The type that is used for the updateStep function. This makes sure the `data` attribute gets added to any form that supports data saving.
 */
type UpdateStepData<S extends StepName> = Partial<Omit<RegisterStep, 'name'>> & (
  S extends keyof StepData
    ? { data?: Partial<StepData[S]> }
    : {}
);

/**
* The default steps used inside the `allSteps` state.
*/
const defaultRegistrationSteps:Array<RegisterStep> = [
  // UserDataForm
  { 
    name: 'UserDataForm', 
    desc: 
      <>
        Laten we beginnen met wat basisgegevens te verzamelen voor uw account. <br/> 
        Heeft u al een account? {' '}
        
        <Link href={'/auth/login'}>
          <span className="text-primary-light underline">Log dan in</span>.
        </Link>
      </>, 
    component: UserDataForm,
    before: null, 
    after: 'PersonalDataForm',
    isActive: true,
  },

  // PersonalDataForm
  {
    name: 'PersonalDataForm',
    desc: 
      <>
        Ook hebben we wat persoonlijke gegevens van u nodig. Daarnaast kunt u nu de rollen uitkiezen die u wilt hebben.
      </>,
    component: PersonalDataForm,
    before: 'UserDataForm',
    after: undefined,
    isActive: true,
  },

  // AddTeacherRoleForm
  {
    name: 'AddTeacherRoleForm',
    desc: 
      <>
        Nu kunt u met Zermelo authenticeren om uw rooster te koppelen en synchroniseren <br/> 
        met Skip Scanner. Ook wordt hiermee uw lerarencode opgehaald. <br/> 
        Voor meer informatie over dit proces, {' '}
        
        <Link href={'/auth/login'}>
          <span className="text-primary-light underline">kijk hier</span>.
        </Link>
      </>,
    component: AddTeacherRole.Form,
    isActive: false,
    before: undefined,
    after: 'AddTeacherRoleOutput'
  },

  // AddTeacherRoleOutput
  {
    name: 'AddTeacherRoleOutput',
    desc: 
      <>
        U bent succesvol geauthenticeerd met uw Zermelo account! <br /> 
        Deze rol is nu toegevoegd aan uw account.
      </>,
    isActive: false,
    component: AddTeacherRole.Output,
    before: 'AddTeacherRoleForm',
    after: undefined
  },

  // AddAdminRoleForm
  {
    name: 'AddAdminRoleForm',
    component: AddAdminRole.Form,
    desc: 
      <>
        Voer hieronder uw administratorscode in die u ontvangen hebt van Skip Scanner. <br/>
        Hiermee kunnen we controleren of u deze rol aan uw account mag toevoegen.
      </>,
    isActive: false,
    before: undefined,
    after: 'AddAdminRoleOutput'
  },

  // AddAdminRoleOutput
  {
    name: 'AddAdminRoleOutput',
    component: AddAdminRole.Output,
    desc: 
      <>
        U bent succesvol geauthenticeerd als administrator! Deze rol is nu <br/> toegevoegd aan uw account.
      </>,
    isActive: false,
    before: 'AddAdminRoleForm',
    after: undefined
  },

  // AddIctRoleForm
  {
    name: 'AddIctRoleForm',
    component: AddIctRole.Form,
    desc:
      <>
        Om devices en gebruiksinstellingen te kunnen beheren heeft u de ICT-rol nodig. <br/>
        Deze kent u hieronder toe middels de aanmeldcode die u ontvangen <br/> heeft van Skip Scanner.
      </>,
    isActive: false,
    before: undefined,
    after: 'AddIctRoleOutput'
  },

  // AddIctRoleOutput
  {
    name: 'AddIctRoleOutput',
    component: AddIctRole.Output,
    desc:
      <>
        U bent succesvol geauthenticeerd als ICT-medewerker!  Deze rol is nu <br/> toegevoegd aan uw account.
      </>,
    isActive: false,
    before: 'AddIctRoleForm',
    after: undefined
  },

  // AddSkscEmployeeRoleForm
  {
    name: 'AddSkscEmployeeRoleForm',
    component: AddSkscEmployeeRole.Form,
    desc:
      <>
        Voer het Skip Scanner medewerkers-wachtwoord in om u te registreren als Skip Scanner medewerker. <br/>
        Bent u geen Skip Scanner medewerker? {' '}         
        
        <Link href={'/auth/register'} onClick={() => window.localStorage.removeItem('registration.activeStepIdx')}>
          <span className="text-primary-light underline">Begin dan opnieuw</span>.
        </Link>
      </>,
    isActive: false,
    before: undefined,
    after: 'AddSkscEmployeeRoleOutput'
  },

  // AddSkscEmployeeRoleOutput
  {
    name: 'AddSkscEmployeeRoleOutput',
    component: AddSkscEmployeeRole.Output,
    desc:
      <>
        U bent succesvol geauthenticeerd als Skip Scanner medewerker! <br/> Deze rol is nu toegevoegd aan uw account.
      </>,
    isActive: false,
    before: 'AddSkscEmployeeRoleForm',
    after: undefined
  },

  // CompleteDataOutput
  {
    name: 'CompleteDataOutput',
    desc: 
      <>
        Hieronder vindt u een overzicht van uw gecreërde account. Klopt er iets niet? <br/>
        Ga dan terug en pas uw gegevens aan. Anders kunt u nu uw account opslaan.
      </>,
    component: CompleteDataOutput,
    isActive: true,
    before: undefined,
    after: null
  }
]

/**
 * A custom hook to manage registration steps within the application. It facilitates the serialization and
 * deserialization of steps for storage in localStorage, alongside managing the active step index for navigation purposes.
 * Additionally, it offers a callback to update step details dynamically. The hook also integrates a mechanism to store 
 * sensitive step-specific data with a validity of 15 minutes, post which the data is cleared from localStorage.
 *
 * @returns {Object} - Object containing the following properties:
 * - steps {RegisterStep[]} - The array of filtered active steps.
 * - activeIdx {number} - The index of the currently active step.
 * - increment {function} - Function to increment the active step index within valid bounds.
 * - decrement {function} - Function to decrement the active step index within valid bounds.
 * - updateStep {function} - Function to update specific fields of a particular step.
 */
export const useRegistrationSteps = () => {

  const TIMEOUT_20MIN = 1000 * 60 * 20 

  /**
   * Serializes an array of RegisterStep objects, keeping only the serializable fields.
   *
   * @param {RegisterStep[]} steps - The steps to serialize.
   * @return {string} - The serialized steps as a JSON string.
   */
  const serializeSteps = useCallback((steps: Array<RegisterStep>) => {
    return JSON.stringify(steps.map(({ name, before, after, isActive }) => ({
      name,
      before,
      after,
      isActive,      
    })));
  }, []);

  /**
   * Deserializes a JSON string to recreate an array of RegisterStep objects.
   * It merges the serialized fields with the default step values to include non-serializable fields.
   *
   * @param {string} serialized - The JSON string representing the serialized steps.
   * @param {RegisterStep[]} defaultSteps - The default step values.
   * @return {RegisterStep[]} - The array of deserialized RegisterStep objects.
   */
  const deserializeSteps = useCallback((serialized: string, defaultSteps: Array<RegisterStep>): Array<RegisterStep> => {
    const parsed: Partial<RegisterStep>[] = JSON.parse(serialized);

    return parsed.reduce((acc, serializedStep) => {
      const defaultStep = defaultSteps.find((step) => step.name === serializedStep.name);
      if (defaultStep) {
        acc.push({
          ...defaultStep,
          ...serializedStep,
        });
      }
      return acc;
    }, [] as RegisterStep[]);
  }, []);

  const [ activeStepIdx, setActiveStepIdx ] = useLocalStorage<number>('registration.activeStepIdx', 0, { timeout: TIMEOUT_20MIN })
  const [ allSteps, setAllSteps ] = useState<Array<RegisterStep>>(defaultRegistrationSteps)
  const [ filteredSteps, setFilteredSteps ] = useLocalStorage<Array<RegisterStep>>('registration.filteredSteps', getSortedActiveSteps(allSteps), {
    serializer: (arr) => serializeSteps(arr),
    deserializer: (str) => deserializeSteps(str, defaultRegistrationSteps),
    timeout: TIMEOUT_20MIN
  })
  const [ stepData, setStepData ] = useLocalStorage<StepData>('registration.stepData', {}, { timeout: TIMEOUT_20MIN })



  /**
   * Callback to update a specific step within the allSteps state array based on the step name and provided updated step metadata.
   * When a step is selected that supports data saving in a separate object inside localStorage, 
   * this is shown inside the second parameter inside the `data` property. 
   *
   * @param {StepName} stepName - The name identifier of the step to be updated.
   * @param {Partial<Omit<RegisterStep, 'name'>>} updatedStepData - An object containing the updated step data, excluding the 'name' property.
   */
  const updateStep = useCallback(<S extends StepName>(
    stepName: S, 
    updatedStepData: UpdateStepData<S>
  ): void => {
    
    // Update the general steps
    setAllSteps((prevSteps) => 
      prevSteps.map((step) => 
        step.name === stepName 
          ? { ...step, ...updatedStepData } 
          : step
      )
    );
  
    // This check ensures that TypeScript understands `data` is possibly available in `updatedStepData`
    if ('data' in updatedStepData) {
      setStepData((prevData) => ({
        ...prevData,
        [stepName]: {
          ...prevData[stepName as keyof StepData],
          ...updatedStepData.data!,
        },
      }));
    }

  }, []);

  const incrementActiveIdx = useCallback(() => {
    setActiveStepIdx((prevIdx) => Math.min(prevIdx + 1, filteredSteps.length - 1));
  }, [filteredSteps]);

  const decrementActiveIdx = useCallback(() => {
    setActiveStepIdx((prevIdx) => Math.max(prevIdx - 1, 0));
  }, []);

  /**
   * Updates the filteredSteps array everytime the allSteps array changes.
   */
  useEffect(() => {
    const sortedActiveSteps = getSortedActiveSteps(allSteps);
    setFilteredSteps(sortedActiveSteps);
  }, [allSteps]);

  return {
    steps: filteredSteps,
    activeIdx: activeStepIdx,
    increment: incrementActiveIdx,
    decrement: decrementActiveIdx,
    updateStep: updateStep,
    data: stepData
  }
}