import React, { useMemo } from 'react'
import Link from 'next/link'
import { useSession, signOut } from 'next-auth/react'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'
import { useRouter } from 'next/router';
import { omit } from 'lodash'
import { createAvatar } from '@dicebear/core'
import { thumbs } from '@dicebear/collection'
import { Button, type ButtonComponentProps } from '@skip-scanner/ui/components/Button'
import { Text } from '@skip-scanner/ui'
import { env } from 'lib/env.mjs'
import { 
  motion, 
  AnimatePresence,
  type HTMLMotionProps, 
  type Transition, 
} from 'framer-motion'
import {
  AdjustmentsHorizontalIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/react/24/outline'
import { EmployeeRoles } from '@skip-scanner/toolkit/types/base'

//#region Navbar Styles

  const navBarStyles = cva('bg-gradient-to-br from-primary-darker to-primary-light overflow-y-auto overflow-x-hidden shadow-lg shadow-gray-700 flex flex-col items-center fixed inset-y-0 left-0 transition-colors duration-150', {
    variants: {
      navSize: {
        'expanded': 'w-60 px-4 from-primary-darker to-primary',
        'collapsed': 'w-16 px-2 from-primary-darker to-primary-light'
      }
    },
    defaultVariants: {
      navSize: 'expanded'
    }
  })

//#endregion

//#region Navbar Props

  export type NavBarComponentProps = HTMLMotionProps<'nav'> &
    {
      expanded: boolean,
      setExpanded: React.Dispatch<React.SetStateAction<boolean>>,
      children?: React.ReactElement<NavLinkComponentProps>[] | React.ReactElement<NavLinkComponentProps>
    }

  export type NavLinkComponentProps = ButtonComponentProps &
    {
      action: string | (() => void),
      allowedRoles?: Array<keyof EmployeeRoles | 'all'>
    }

//#endregion

export const NavBar:React.FC<NavBarComponentProps> = ({ expanded, setExpanded, ...props }) => {

  const { data:session } = useSession()
  const router = useRouter()

  const avatar = useMemo(() => {

    return createAvatar(thumbs, {
      seed: session?.user.id,
      size: 40,
      scale: 80,
      shapeColor: ["226D5C"],
      backgroundColor: ['FFFFFF'],
    }).toDataUriSync();

  }, [session]);

  const defaultTransition:Transition = {
    type: 'spring',
    stiffness: 400,
    damping: 50,
  }

  return (
    <motion.nav
      layout
      transition={defaultTransition}
      className={twMerge( 
        props.className, 
        navBarStyles({ navSize: expanded == true ? 'expanded' : 'collapsed'}) 
      )}
      aria-expanded={expanded} 
      {...omit(props, 'className')}
    >
      
      {/* Logo */}
      <Link href={'/dashboard'}>
        <motion.div 
          layout
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className={`mt-6 pb-1 cursor-pointer w-min border-b-2 border-primary-lighter select-none flex flex-row gap-x-2 relative overflow-x-hidden`}
        >
          
          {/* "Skip" text */}
          <AnimatePresence>
            {expanded && 
              <Text.Heading 
                className='text-heading-md'
                textColor={'white'} 
                layout
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -25, scale: 0.45 }}
                key={'Skip'}
              > Skip </Text.Heading> 
            }
          </AnimatePresence>
          
          {/* Eye Icon */}
          <motion.svg 
            transition={defaultTransition}                
            initial={{ opacity: 0, x: 0, scale: 0.5 }}
            animate={{ opacity: 1, x: 0, scale: 1 }}
            exit={{ opacity: 0, x: 0, scale: 0.5 }}
            className={`${expanded ? 'top-[5px] h-6 w-6' : 'h-7 w-7'} text-white inline-block relative`}
            fill='currentColor' 
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
            key={'Eye'}
          >
            <motion.path d="M12 15a3 3 0 100-6 3 3 0 000 6z" fill='currentColor' className='text-accent'/>
            <motion.path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clipRule="evenodd" />
          </motion.svg>

          {/* "Scanner" text */}
          <AnimatePresence>
            {expanded && 
              <Text.Heading 
                className='text-heading-md'
                textColor={'white'} 
                layout
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -25, scale: 0.45 }}
                key={'Scanner'}
              > Scanner </Text.Heading> 
            } 
          </AnimatePresence>

        </motion.div>

      </Link>

      {/* NavLinks */}
      <motion.div
        layout
        className={`${expanded && 'w-[90%]'} mt-6 flex flex-col items-center gap-y-3`}
      > 
        {props.children}
      </motion.div>
      
      {/* User item */}
      <motion.div
        layout
        className={`${expanded && 'w-[90%]'} mt-auto mb-6 pt-1 flex flex-row`}
      >

        {/* Icons on the left */}
        <motion.div 
          className={`${expanded ? 'mr-4 min-w-max items-start' : 'items-center'} flex flex-col justify-between`}
          layout
        >

          {/* Avatar icon */}
          <Link href={'/account'}>
            <motion.img 
              layout
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              src={avatar}
              height={40}
              width={40}
              alt='User Icon'
              className={`relative top-1 h-8 w-8 rounded-full ring ring-primary-lighter bg-neutral-100 text-sm text-center cursor-pointer`}
            />
          </Link>

          {/* Collapse button */}
          <motion.svg
            transition={defaultTransition}        
            initial={{ rotate: 0 }}        
            animate={ expanded ? "expanded" : "collapsed" }
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            variants={{ 
              expanded: { rotate: 180 },
              collapsed: { rotate: 0 }
            }}
            className={`block mt-6 w-6 relative bottom-[2px] text-neutral-400 cursor-pointer`} 
            fill='currentColor' 
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
            key={'CollapseBtn'}
            onClick={() => setExpanded(!expanded)}
          >
            <motion.path fillRule="evenodd" d="M10.21 14.77a.75.75 0 01.02-1.06L14.168 10 10.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
            <motion.path fillRule="evenodd" d="M4.21 14.77a.75.75 0 01.02-1.06L8.168 10 4.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
          </motion.svg>

        </motion.div>

        {/* Extra text */}
        <AnimatePresence>
          {expanded && 
            <motion.div
              layout
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 10, scale: 0 }}
              className='block w-full'
            >

              {/* User name and roles */}
              <Link href={'/account'}>
                <Text.Label className='text-label-lg leading-5 mb-1 hover:underline cursor-pointer select-none' textColor={'white'}>
                  {session?.user.personal.firstName}
                  {' '}
                  {session?.user.personal.prefix ?? ''}
                  {' '}
                  {session?.user.personal.lastName}
                </Text.Label>
              </Link>
              <Text.Label className='text-label-sm leading-4 text-neutral-400 select-none'>
                {Object.entries(session ? session.user.roles : {})
                  .filter(([_, value]) => value)
                  .map(([key]) => ({ teacher: 'Leraar', mentor: 'Mentor', administrator: 'Administrator', ict: 'ICT-medewerker', 'sksc-employee': 'Sksc-medewerker' }[key]))
                  .join(', ')
                }
              </Text.Label> 

              <hr className='my-3'/>

              {/* Extra links */}
              <Text.Label 
                textIntent={'link'} 
                href='/account/instellingen' 
                className='text-label-sm leading-5 !text-primary-lighter select-none'
              >
                Instellingen
                <AdjustmentsHorizontalIcon className='inline relative bottom-[1px] ml-1 h-[18px]'/>
              </Text.Label>

              <Text.Label 
                className='text-label-sm mt-[2px] leading-5 !text-primary-lighter hover:underline cursor-pointer select-none' 
                onClick={async () =>  signOut({ callbackUrl: '/auth'}) }
              >
                Uitloggen
                <ArrowRightOnRectangleIcon className='inline relative ml-1 h-[18px]'/>
              </Text.Label>

            </motion.div>
          }
        </AnimatePresence>

      </motion.div>

    </motion.nav>
  )
}

/**
 * The NavLink component that can only be passed as a direct child of the NavBar component.
 * Supports link prefetching or custom activation functions.
 * 
 * ---
 * The `userType` prop needs to be passed to determine if the NavLink is available to this user.
 * When extracting default types into a default type library, types can be passed to install automatic `next-auth`
 * userType recognition, using the `useSession` hook.
 * 
 * @returns {React.FC}
 */
export const NavLink:React.FC<NavLinkComponentProps> = ({ action, allowedRoles = ['all'], ...props }) => {

  const router = useRouter();
  const { data:session } = useSession()

  if(!session) return null
  if(allowedRoles.length == 0) throw new Error('Een Navlink moet min. 1 rol toegewezen hebben. Standaard is "all".')
  
  const hasAllowedRole = allowedRoles.some((role) => role === 'all' || session.user.roles[role as keyof EmployeeRoles]);
  if(!hasAllowedRole) return null;

  if(typeof action == 'string') return (
    <Link href={action}>

      <Button 
        btnState={router.pathname.startsWith(action) ? 'active' : 'default'}
        className={props.className}
        {...omit(props, 'className')}
      >
        {props.children}
      </Button>

    </Link>
  )

  else return (
    <Button 
      btnState={'default'} 
      onClick={action} 
      className={props.className}
      {...omit(props, 'className', 'onClick')}
    >
      {props.children}
    </Button>
  )

}