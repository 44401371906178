import React, { useState } from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'
import { 
  motion, 
  type HTMLMotionProps, 
  type Transition 
} from 'framer-motion'
import { omit } from 'lodash'

const mainStyles = cva('px-8 min-h-screen overflow-x-hidden relative py-6', {
  variants: {
    mainSize: {
      'expanded': 'ml-60',
      'collapsed': 'ml-16'
    },
    mainOverflow: {
      'auto': 'overflow-y-auto scrollbar-thin',
      'hidden': 'overflow-y-hidden flex flex-col max-h-screen'
    }
  },
  defaultVariants: {
    mainSize: 'expanded',
    mainOverflow: 'auto'
  }
})

export type MainComponentProps = HTMLMotionProps<'nav'> &
  {
    expanded?: boolean,
    overflow?: boolean,
    children?: React.ReactNode 
  }

export const Main:React.FC<MainComponentProps> = ({ expanded = false, overflow = true, ...props }) => {

  const defaultTransition:Transition = {
    type: 'spring',
    stiffness: 500,
    damping: 50,
  }

  return (
    <motion.main
      layout
      transition={defaultTransition}
      className={twMerge( 
        props.className, 
        mainStyles({ 
          mainSize: expanded ? 'collapsed': 'expanded', 
          mainOverflow: overflow? 'auto' : 'hidden' 
        }) 
      )}
      aria-expanded={expanded} 
      {...omit(props, 'className')}
    >
      {props.children}
    </motion.main>
  )
}